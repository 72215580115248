import React, { useState } from 'react';
import { BsEyeSlashFill } from 'react-icons/bs';
import { IoEyeSharp } from 'react-icons/io5';
import { usePageContext } from '~/hooks';

import { Button, Input, ModalDefault, ModalUpdate } from '~/components';
import Logo from '~/assets/LogoEBDColorida.png';
import authService from '~/services/authService';
import { api } from '~/services/api';
import { Container, Content, ImgMain } from './styles';

const Login: React.FC = () => {
  const [fail, setFail] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisibleModalUpdate, setIsVisibleModalUpdate] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const {
    setIsLogged,
    setChurch,
    setLeader,
    setLeaderId,
    setBiblicalSchoolDay,
  } = usePageContext();

  const validateUpdate = async () => {
    const response = await authService.validateUpdate();
    if (!response.needUpdateClasse) {
      setIsLogged(true);
    } else {
      setIsLogged(true);

      setIsVisibleModalUpdate(true);
    }
  };

  const login = async () => {
    try {
      const response = await authService.login({ email, password });
      if (response?.token) {
        api.defaults.headers.common.Authorization = `Bearer ${response.token}`;
        setChurch(response.user.church);
        setLeader(response.user.leader);
        setLeaderId(response.user.leaderId);
        setBiblicalSchoolDay(response.user.biblicalSchoolDay);
        validateUpdate();
      }
    } catch {
      setFail(true);
    }
  };

  return (
    <>
      {isVisibleModalUpdate && <ModalUpdate onSave={validateUpdate} />}
      {fail && (
        <ModalDefault success={false} onClick={() => setFail(false)}>
          Por favor, verifique seu e-mail e sua senha e tente novamente!
        </ModalDefault>
      )}
      <Container
        onKeyUp={(e: any) => {
          if (e.key === 'Enter') login();
        }}
      >
        <Content>
          <ImgMain src={Logo} alt="Logo EBD" />
          <Input
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Input
            placeholder="Senha"
            type={!visiblePassword ? 'password' : 'text'}
            value={password}
            icon={
              !visiblePassword ? (
                <BsEyeSlashFill color="#b8b8b8" />
              ) : (
                <IoEyeSharp color="#b8b8b8" />
              )
            }
            onClick={() => setVisiblePassword(!visiblePassword)}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            onClick={() => {
              login();
            }}
          >
            Entrar
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default Login;
