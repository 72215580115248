/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Header, Input, Loading, Return } from '~/components';
import {
  getDay,
  formatDecimal,
  generateDefaultInputValues,
  generateRequiredInputValues,
  getDateOfWeek,
  isEmpty,
  validateForm,
} from '~/utils';
import ModalDefalut from '~/components/ModalDefault';
import frequencyService from '~/services/frequencyService';
import { useHistory } from 'react-router-dom';
import { usePageContext } from '~/hooks';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { AiOutlineEdit } from 'react-icons/ai';
import {
  Container,
  Content,
  DateArea,
  ErroAlert,
  ParsonContainer,
  Select,
  SubTitle,
  TextArea,
  Title,
} from './styles';
import ModalDate from './ModalDate';
import { Label } from './ModalDate/styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const Frequency: React.FC = () => {
  const state: string[] = [];
  const stateJustification: string[] = [];

  const history = useHistory();

  const stateSchema = {
    ...generateRequiredInputValues(['offer', 'tithe']),
    ...generateDefaultInputValues(['observation', 'date', 'justificationDate']),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [listClasses, setListClasses] = useState([]);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [MessageModalErro, setMessageModalErro] = useState(
    'Ops... algo deu errado!',
  );
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalDate, setIsVisibleModalDate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [parson, setParson] = useState<'' | boolean>('');
  const [erroParson, setErroParson] = useState('');
  const [lesson, setLesson] = useState({ quarter: 0, lesson: 0 });
  const [inputDate, setInputDate] = useState('');
  const [inputJustification, setInputJustification] = useState('');
  const { leader, biblicalSchoolDay } = usePageContext();

  const handleInput = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (
      inputName !== 'observation' &&
      inputName !== 'tithe' &&
      inputName !== 'offer' &&
      inputName !== 'date' &&
      !inputName.toString().includes('justification')
    ) {
      return setInputState((prevState: any) => ({
        ...prevState,
        [inputName]: {
          isValid,
          value,
          required: inputState[inputName].required,
          error,
        },
        [`justification${inputName}`]: {
          isValid: true,
          value: inputState[`justification${inputName}`].value,
          required: false,
          error: '',
        },
      }));
    }

    if (inputState![inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    return setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  useEffect(() => {
    const loadClasses = async () => {
      setLoading(true);
      const responseClasses = await frequencyService.listMyClasses();
      const responseLesson = await frequencyService.getLesson();
      setLesson(responseLesson);
      setListClasses(responseClasses);
      responseClasses.forEach((item: any) => {
        state.push(item.id);
        stateJustification.push(`justification${item.id}`);
      });
      const stateSchema = {
        ...generateRequiredInputValues([...state, 'offer', 'tithe']),
        ...generateDefaultInputValues([
          'observation',
          'date',
          'justificationDate',
          ...stateJustification,
        ]),
      };
      setInputState(stateSchema);
      const date = new Date(
        getDateOfWeek(
          responseLesson.lesson,
          responseLesson.quarter,
          getDay(biblicalSchoolDay),
        ),
      )
        .toISOString()
        .split('T')[0];
      handleInput(date, 'date');
      setLoading(false);
    };
    loadClasses();
  }, []);

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    if (parson === '' || disabled) {
      hasError = true;
    }

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    Object.entries(inputState).forEach(allInput => {
      if (
        allInput[0] !== 'observation' &&
        allInput[0] !== 'tithe' &&
        allInput[0] !== 'offer' &&
        allInput[0] !== 'date' &&
        !allInput[0].includes('justification')
      ) {
        if (
          allInput[1].value === '0' &&
          inputState[`justification${allInput[0]}`].value.length === 0
        ) {
          return setInputState((prevState: any) => ({
            ...prevState,
            [`justification${allInput[0]}`]: {
              isValid: false,
              value: inputState[`justification${allInput[0]}`].value,
              required: true,
              error: 'campo obrigatorio',
            },
          }));
        }
      }
      return null;
    });

    const create = async () => {
      const state: any = [];

      Object.entries(inputState).forEach(allInput => {
        if (allInput[0].includes('justification')) {
          return null;
        }
        if (
          allInput[0] !== 'observation' &&
          allInput[0] !== 'tithe' &&
          allInput[0] !== 'offer' &&
          allInput[0] !== 'date'
        ) {
          if (allInput[1].value === '0') {
            return state.push({
              id: allInput[0],
              attendance: allInput[1].value,
              justification: inputState[`justification${allInput[0]}`].value,
            });
          }
          state.push({ id: allInput[0], attendance: allInput[1].value });
        }
        return null;
      });
      if (isFormValid()) {
        if (parson !== '') {
          try {
            setLoading(true);
            await frequencyService.CreateFrequency({
              date: new Date(`${inputState.date.value}T03:00:00`),
              pastor_present: parson,
              tithes: inputState.tithe.value,
              offers: inputState.offer.value,
              comments: inputState.observation.value,
              classes: state,
              quarter_number: lesson.quarter,
              lesson_number: lesson.lesson,
              is_out_of_date:
                inputState.date.value !==
                new Date(
                  getDateOfWeek(
                    lesson.lesson,
                    lesson.quarter,
                    getDay(biblicalSchoolDay),
                  ),
                )
                  .toISOString()
                  .split('T')[0],
              out_of_date_justification:
                inputState.justificationDate.value || undefined,
            });
            setIsVisibleModalSuccess(true);
          } catch (e: any) {
            if (e.status === 400) {
              setMessageModalErro(e.error.message);
            } else {
              setMessageModalErro('Ops... algo deu errado!');
            }
            setIsVisibleModalErro(true);
          }
          setDisabled(false);
          setLoading(false);
        } else {
          setErroParson('campo obrigatorio');
        }
      } else {
        setIsVisibleModalErro(true);
      }
    };
    create();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalDate && (
        <ModalDate
          lesson={lesson}
          inputDate={inputDate}
          setInputDate={setInputDate}
          inputJustification={inputJustification}
          setInputJustification={setInputJustification}
          onCloseModal={() => setIsVisibleModalDate(false)}
          onSave={(inputDate, inputJustification) => {
            handleInput(inputDate, 'date');
            handleInput(inputJustification, 'justificationDate');
          }}
        />
      )}
      {isVisibleModalErro && (
        <ModalDefalut
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {MessageModalErro}
        </ModalDefalut>
      )}
      {isVisibleModalSuccess && (
        <ModalDefalut success onClick={() => history.push('/home')}>
          Relatório enviado com sucesso!
        </ModalDefalut>
      )}
      <Container>
        <Header />
        <Return />
        <Content>
          <Title>Cadastrar frequência</Title>
          <SubTitle>
            Lição {lesson.lesson} ({lesson.quarter}º trimestre)
          </SubTitle>
          <span>
            <Label>
              <b>Confirme a data</b>
            </Label>
            <DateArea onClick={() => setIsVisibleModalDate(true)}>
              {format(
                new Date(`${inputState.date.value}T03:00:00`),
                "dd 'de' MMMM 'de' y",
                {
                  locale: pt,
                },
              )}
              <AiOutlineEdit />
            </DateArea>
            <ParsonContainer>
              <b>Dirigente: {leader}</b>
              {/* <Button onClick={() => window.prompt('Digite o nome do pastor')}>
                Alterar Dirigente
              </Button> */}
            </ParsonContainer>
          </span>
          <span>
            <b>Pastor(Dirigente) da Congregação Presente?</b>
          </span>
          <Select
            error={!!erroParson}
            onChange={e => {
              if (e.target.value === '0') {
                setParson(false);
              }
              if (e.target.value === '1') {
                setParson(true);
              }
              if (e.target.value === '') {
                setParson('');
              }
            }}
          >
            <option value=""> </option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </Select>
          {erroParson && <ErroAlert>{erroParson}</ErroAlert>}
          {listClasses.map((item: any) => {
            return (
              <span key={item.id}>
                <Input
                  type="number"
                  label={`Classe de ${item.name}:`}
                  placeholder="Quantidade de alunos"
                  error={inputState[item.id].error}
                  value={
                    inputState[item.id].value.replace(/[^\d]+/g, '') || ' '
                  }
                  onChange={e => {
                    handleInput(e.target.value.replace(/[^\d]+/g, ''), item.id);
                  }}
                />
                {inputState[item.id].value === '0' && (
                  <Input
                    label={`Justificativa da ausência(${item.name}):`}
                    placeholder="Justifique a ausência da turma"
                    error={inputState[`justification${item.id}`].error}
                    value={inputState[`justification${item.id}`].value}
                    onChange={e =>
                      handleInput(e.target.value, `justification${item.id}`)
                    }
                  />
                )}
              </span>
            );
          })}
          <Input
            label="Valor Total de Oferta"
            value={formatDecimal(inputState.offer.value)}
            error={inputState.offer.error}
            onChange={e => handleInput(e.target.value, 'offer')}
          />
          <Input
            label="Valor Total de Dízimo"
            value={formatDecimal(inputState.tithe.value)}
            error={inputState.tithe.error}
            onChange={e => handleInput(e.target.value, 'tithe')}
          />
          <span>
            <b>Observação:</b>
            <TextArea
              value={inputState.observation.value}
              onChange={e => handleInput(e.target.value, 'observation')}
            />
          </span>
          <Button
            onClick={() => {
              setDisabled(true);
              validAll();
            }}
            disabled={!isFormValid()}
          >
            Salvar
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default Frequency;
