interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

const defaultInput = { value: '', error: '', isValid: true };
const requiredTrue = { ...defaultInput, required: true };

const initialInputValue = {
  defaultInput,
  required: requiredTrue,
};

export const isEmpty = (item: string | Object): Boolean => {
  if (typeof item === 'string') {
    return item.trim().length === 0;
  }

  if (item instanceof Object) {
    return Object.entries(item).length === 0;
  }

  return false;
};

export const setInputValue = (value: {
  value: any;
  error?: string;
  required?: true | false;
  isValid?: true | false;
}): IPropsInput => {
  const initialValue = initialInputValue.required;

  if (typeof value === 'object') {
    return { ...initialValue, ...value };
  }

  return { ...initialValue, value };
};

export const validateForm = (state: {
  [key: string]: IPropsInput;
}): { [key: string]: IPropsInput } => {
  const inputsWithError: { [key: string]: IPropsInput } = {};

  Object.entries(state).forEach((input: any) => {
    const [key, { value, required }] = input;

    // Campos obrigatórios
    if (isEmpty(value) && required) {
      inputsWithError[key] = setInputValue({
        value,
        error: 'Campo obrigatório',
      });
    }
  });

  return inputsWithError;
};

export const generateInputValues = (array: any[], required = false): object => {
  const inputs: any = {};

  array.forEach((input: any) => {
    inputs[input] = required
      ? initialInputValue.required
      : initialInputValue.defaultInput;
  });

  return inputs;
};

export const generateDefaultInputValues = (array: any[]): object =>
  generateInputValues(array);

export const generateRequiredInputValues = (array: any[]): object =>
  generateInputValues(array, true);

export const changeMoney = (money: string): string => {
  let number = money.replaceAll(/[^a-z0-9]/gm, '').trim();

  if (number.length === 1) number = `0${number}`;

  number = `${number.substr(0, number.length - 2)}.${number.substr(
    number.length - 2,
  )}`;

  return number;
};

export const formatDecimal = (money: string): string => {
  let number = money.replaceAll(/[^a-z0-9]/gm, '').trim();

  if (number.length === 1) number = `0${number}`;

  number = `${number.substr(0, number.length - 2)}.${number.substr(
    number.length - 2,
  )}`;

  return number.replace(/^(0+)(\d)/g, '$2');
};

export const getDateOfWeek = (
  lesson: number,
  quarter: number,
  day: number,
): Date => {
  const data = new Date(`${new Date().getFullYear()}-01-01T00:00:00`);
  const diaDaSemana = data.getDay();
  const quarterLeasson = { 1: 0, 2: 13, 3: 26, 4: 39 };

  if (diaDaSemana !== day) {
    data.setDate(data.getDate() + (day - diaDaSemana));
  }

  data.setDate(
    data.getDate() +
      7 * (lesson + quarterLeasson[quarter as 1 | 2 | 3 | 4] - 1),
  );

  return data;
};

export const getDay = (day: string): number => {
  const enumDay = {
    Domingo: 0,
    'Segunda-feira': 1,
    'Terça-feira': 2,
    'Quarta-feira': 3,
    'Quinta-feira': 4,
    'Sexta-feira': 5,
    Sabado: 6,
  };
  return (
    enumDay[
      day as
        | 'Domingo'
        | 'Segunda-feira'
        | 'Terça-feira'
        | 'Quarta-feira'
        | 'Quinta-feira'
        | 'Sexta-feira'
        | 'Sabado'
    ] || enumDay.Domingo
  );
};
