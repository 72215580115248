import Logo from '~/assets/LogoEBDColorida.png';
import { usePageContext } from '~/hooks';
import { Container } from './styles';

const Header: React.FC = () => {
  const { setIsLogged, church } = usePageContext();

  return (
    <Container>
      <img alt="Logo EBD" src={Logo} />
      <h1>{church}</h1>
      <button type="button" onClick={() => setIsLogged(false)}>
        Sair
      </button>
    </Container>
  );
};

export default Header;
