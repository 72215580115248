import { api } from './api';

interface IUpdatePassword {
  actualPassword: string;
  password: string;
}
class UserService {
  async updatePassword({
    password,
    actualPassword,
  }: IUpdatePassword): Promise<any> {
    const response = await api.patch(`/bs/users/password/me`, {
      password,
      actualPassword,
    });

    return response.data;
  }
}

export default new UserService();
