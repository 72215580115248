import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { IoEyeSharp } from 'react-icons/io5';
import { Header, Loading, Return, TablePage } from '~/components';
import frequencyService from '~/services/frequencyService';
import ModalDetails from './ModalDetails';
import { Container, Content, Title } from './styles';

const Reports: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isVisibleModalInfo, setIsVisibleModalInfo] = useState(false);
  const [handleIdInfo, setHandleIdInfo] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const loadChurchs = async () => {
    setLoading(true);
    const res = await frequencyService.ListFrequency();

    setDataSource(res.result);
    setLoading(false);
  };

  useEffect(() => {
    loadChurchs();
  }, []);

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (e: any) =>
        `${e && format(new Date(e), "dd 'de' MMMM 'de' y", { locale: pt })}`,
    },
    {
      title: 'Presentes',
      dataIndex: 'totalPresence',
      key: 'totalPresence',
    },
    {
      key: 'frequencyClasse',
      width: '60px',
      render: (e: any) => (
        <>
          <button
            type="button"
            onClick={() => {
              setHandleIdInfo(e);
              setIsVisibleModalInfo(true);
            }}
          >
            <IoEyeSharp />
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalInfo && (
        <ModalDetails
          onClick={() => setIsVisibleModalInfo(false)}
          list={handleIdInfo}
        />
      )}
      <Container>
        <Header />
        <Return />
        <Content>
          <Title>Cadastros de frequência</Title>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey="id"
          />
        </Content>
      </Container>
    </>
  );
};

export default Reports;
