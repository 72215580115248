import { useState, createContext } from 'react';
// import authService from '~/services/authService';

export interface IPageContext {
  collapseMenu: boolean;
  setCollapseMenu: React.Dispatch<React.SetStateAction<boolean>>;
  fixed: boolean;
  setFixed: React.Dispatch<React.SetStateAction<boolean>>;
  focus: boolean;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  church: string;
  setChurch: React.Dispatch<React.SetStateAction<string>>;
  leader: string;
  setLeader: React.Dispatch<React.SetStateAction<string>>;
  leaderId: number;
  setLeaderId: React.Dispatch<React.SetStateAction<number>>;
  biblicalSchoolDay: string;
  setBiblicalSchoolDay: React.Dispatch<React.SetStateAction<string>>;
  // menu: any[];
}

const PageContext = createContext<IPageContext>({} as IPageContext);

const PageProvider: React.FC = ({ children }: any) => {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [focus, setFocus] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [church, setChurch] = useState('');
  const [leader, setLeader] = useState('');
  const [leaderId, setLeaderId] = useState(0);
  const [biblicalSchoolDay, setBiblicalSchoolDay] = useState('');
  // const [menu, setMenu] = useState([]);

  // useEffect(() => {
  //   const load = async () => {
  //     const resquest = await authService.showMenu();
  //     setMenu(resquest);
  //   };
  //   if (isLogged) {
  //     load();
  //   }
  // }, [isLogged]);

  return (
    <PageContext.Provider
      value={{
        collapseMenu,
        setCollapseMenu,
        fixed,
        setFixed,
        focus,
        setFocus,
        isLogged,
        setIsLogged,
        church,
        setChurch,
        leader,
        setLeader,
        leaderId,
        setLeaderId,
        biblicalSchoolDay,
        setBiblicalSchoolDay,
        // menu,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
export { PageContext };
